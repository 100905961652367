import axios, { AxiosRequestConfig } from "axios";
import { getApiUrl } from "./getApiUrl";
import { useAuthStore } from "store/authentication";
import { AuthDetailsType } from "src/types/types";
import * as Sentry from "@sentry/react";

/**
 *
 * Zustand funcs
 */

const getAuthStore = (): AuthDetailsType => {
  return useAuthStore.getState();
};

/**
 *
 * Axios instances
 */
type axiosOptionsT = {
  authentication?: boolean;
  isAdmin?: boolean;
};
const axiosOptions = ({ authentication, isAdmin }: axiosOptionsT) => {
  return {
    baseURL: getApiUrl(authentication ? undefined : "/api", isAdmin),
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "nl-nl",
    },
  };
};

// ------------------ Authenticated axios instance ------------------
// Authenticated axios instance
const authInstance = axios.create(axiosOptions({ authentication: true }));

// ------------------ General axios instance ------------------
// General axios instance
const instance = axios.create(axiosOptions({}));

// Set the AUTH token for any request
instance.interceptors.request.use(async (config) => {
  const { access_token } = getAuthStore();
  // generate unique transactionId and set as Sentry tag
  const scope = Sentry.getCurrentScope();
  const transactionId = Math.random().toString(36).substr(2, 9);
  scope.setTag("transaction_id", transactionId);
  config.headers.Authorization = access_token ? `Bearer ${access_token}` : "";
  return config;
});

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    const { refreshUserToken, clearAuth, refresh_token } = getAuthStore();
    if (error.response && error.response.status === 401 && refresh_token) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Refresh the access token
          await refreshUserToken();

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            instance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return instance(originalRequest);
        } catch (refreshError) {
          clearAuth();
          window.location.href = "/welcome";
          console.error(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        clearAuth();
        window.location.href = "/welcome";
        isRefreshing = false;
      }

      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  }
);

// ------------------ Admin Authenticated axios instance ------------------
// Admin authenticated axios instance
const adminAuthInstance = axios.create(
  axiosOptions({ authentication: true, isAdmin: true })
);
const adminInstance = axios.create(axiosOptions({}));
// Set the AUTH token for any request of Admin authenticated axios instance
adminInstance.interceptors.request.use(async (config) => {
  const { admin } = getAuthStore();
  const access_token = admin?.access_token;
  // generate unique transactionId and set as Sentry tag
  const scope = Sentry.getCurrentScope();
  const transactionId = Math.random().toString(36).substr(2, 9);
  scope.setTag("transaction_id", transactionId);
  config.headers.Authorization = access_token ? `Bearer ${access_token}` : "";
  return config;
});

// Create a list to hold the request queue
const adminRefreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isAdminRefreshing = false;
// Response interceptor for API calls
adminInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    const { refreshAdminToken, clearAdminAuth, admin } = getAuthStore();
    if (
      error.response &&
      error.response.status === 401 &&
      admin?.refresh_token
    ) {
      if (!isAdminRefreshing) {
        isAdminRefreshing = true;
        try {
          // Refresh the access token
          await refreshAdminToken();

          // Retry all requests in the queue with the new token
          adminRefreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            adminInstance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          adminRefreshAndRetryQueue.length = 0;

          // Retry the original request
          return adminInstance(originalRequest);
        } catch (refreshError) {
          clearAdminAuth();
          console.error(refreshError);
        } finally {
          isAdminRefreshing = false;
        }
      }

      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        adminRefreshAndRetryQueue.push({
          config: originalRequest,
          resolve,
          reject,
        });
      });
    }

    return Promise.reject(error);
  }
);

// Export axios instances
export { instance, authInstance, adminInstance, adminAuthInstance };
